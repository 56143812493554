<template>
  <div>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('SUPERADMIN.SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers_students_table"
      :items="dataTable"
      :options.sync="options"
      :loading="loading"
      :search="search"
      show-expand
      single-expand
      multi-sort
      class="elevation-1"
      item-key="applicationId"
    >
      <!-- NAME -->
      <template v-slot:[`item.campus`]="{ item }">
        {{ item.campus }}
      </template>
      <!-- STATE -->
      <template v-slot:[`item.state`]="{ item }">
        {{ item.calification }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pt-5 pb-5">
          <v-card>
            <v-card-text>
              {{ item.review }}
              <br /><br />
              <v-select
                outlined
                dense
                label="Change status"
                :items="ratedByOptions"
                :error-messages="errors"
                v-model="value"
                item-text="Name"
                @change="changeStatus(value, item.applicationId)"
                v-bind:id="item.applicationId"
              >
              </v-select>
            </v-card-text>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_ALL_REVIEWS_CUSTOM } from "@/core/services/store/reviews/reviews.module";
import { UPDATE_STATUS_REVIEW } from "@/core/services/store/reviews/reviews.module";
export default {
  data() {
    return {
      totalApplications: 0,
      loading: true,
      rowsPerPageItems: [10],
      search: "",
      pagination: {
        rowsPerPage: 10,
      },
      headers_students_table: [
        {
          text: this.$t("REVIEWS.TABLE.REVIEWS_HEADER.ITEM1"),
          value: "applicationId",
          sortable: true,
        },
        {
          text: this.$t("REVIEWS.TABLE.REVIEWS_HEADER.ITEM2"),
          value: "campus",
          sortable: true,
        },
        {
          text: this.$t("REVIEWS.TABLE.REVIEWS_HEADER.ITEM3"),
          value: "estudiante",
          sortable: true,
        },
        {
          text: this.$t("REVIEWS.TABLE.REVIEWS_HEADER.ITEM4"),
          value: "state",
          sortable: true,
          align: "center",
        },
      ],
      //
      dataTable: [],
      options: {},
      ratedByOptions: [
        {
          Name: "REJECTED",
          value: 0,
        },
        {
          Name: "APPROVED",
          value: 1,
        },
        {
          Name: "PENDING",
          value: 2,
        },
        {
          Name: "PENDING VERIFY",
          value: 3,
        },
      ],
    };
  },
  props: {
    status: String,
    newHeaders: {
      type: Boolean,
      default: false,
    },
    headersProduct: {
      type: Array,
      default: null,
    },
    headersStudent: {
      type: Array,
      default: null,
    },
    filterBy: {
      type: String,
      default: "all",
    },
  },
  watch: {
    filterBy: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.newHeaders) {
      this.headers_students_table = this.headersStudent;
      this.headers_product_table = this.headersProduct;
    }
    this.getDataFromApi();
  },
  methods: {
    async changeStatus(status, review) {
      let data = {
        status: status,
        review: review,
      };
      this.$store
        .dispatch(UPDATE_STATUS_REVIEW, data)
        .then(() => {
          this.save_new_loading = false;

          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.save_new_loading = false;
        });
    },
    getDataFromApi() {
      this.loading = true;
      const { page, sortBy, sortDesc } = this.options;
      let auxSortBy = sortBy.length > 0 ? `&sort_by=${sortBy[0]}` : "";
      let auxType;
      if (sortDesc.length > 0) {
        auxType = sortDesc[0] ? "DESC" : "ASC";
      } else {
        auxType = "DESC";
      }
      let auxSortDesc = sortDesc.length > 0 ? auxType : "DESC";
      const query = `status=${this.status}&page=${page}${auxSortBy}&sort_type=${auxSortDesc}&filter_by=${this.filterBy}`;
      this.$store.dispatch(GET_ALL_REVIEWS_CUSTOM, query).then(data => {
        this.dataTable = this.formatData(data, this.campus.date_format);
        this.loading = false;
      });
    },
    getCampus(data) {
      if (data !== null) {
        return data.name;
      }
    },
    getStatus(status) {
      let statusInfo;
      switch (status) {
        case 1:
          statusInfo = {
            color: null,
            text: this.$t("APPLICATION.STATUS.ITEM1"),
          };
          break;
        case 2:
          statusInfo = {
            color: "green",
            text: this.$t("APPLICATION.STATUS.ITEM2"),
          };
          break;
        case 3:
          statusInfo = {
            color: "red",
            text: this.$t("APPLICATION.STATUS.ITEM3"),
          };
          break;
        case 4:
          statusInfo = {
            color: "deep-orange",
            text: this.$t("APPLICATION.STATUS.ITEM4"),
          };
          break;

        case 5:
          statusInfo = {
            color: "teal",
            text: this.$t("APPLICATION.STATUS.ITEM5"),
          };
          break;

        case 6:
          statusInfo = {
            color: "cyan",
            text: this.$t("APPLICATION.STATUS.ITEM6"),
          };
          break;

        default:
          statusInfo = {
            color: null,
            text: this.$t("APPLICATION.STATUS.ITEM1"),
          };
          break;
      }

      return statusInfo;
    },

    getIcon(productTypeId) {
      let iconInfo;
      switch (productTypeId) {
        case 1:
          iconInfo = {
            icon: "book",
            text: this.$t("APPLICATION.ICONS.ITEM1"),
          };
          break;
        case 2:
          iconInfo = {
            icon: "bed",
            text: this.$t("APPLICATION.ICONS.ITEM2"),
          };
          break;
        case 3:
          iconInfo = {
            icon: "car",
            text: this.$t("APPLICATION.ICONS.ITEM3"),
          };
          break;
        case 6:
          iconInfo = {
            icon: "boxes",
            text: this.$t("APPLICATION.ICONS.ITEM4"),
          };
          break;

        default:
          iconInfo = {
            icon: "book",
            text: this.$t("APPLICATION.ICONS.ITEM1"),
          };
          break;
      }

      return iconInfo;
    },

    formatData(data, date_format) {
      const newData = [];
      data.data?.forEach(item => {
        const { Student, ListReviews } = item;
        const icon_status = [];
        const auxGeneralStatus = [];
        ListReviews?.forEach((element, index) => {
          const { productTypeId } = element.Product.product_type;
          const { applicationStatusId } = element.ApplicationStatus;
          icon_status.push(this.getIcon(productTypeId));
          ListReviews[index].status = this.getStatus(applicationStatusId);
          auxGeneralStatus.push(applicationStatusId);
        });

        let generalStatus = 0;
        let auxAccepted = 0;

        if (auxGeneralStatus.length === 1) {
          generalStatus = auxGeneralStatus[0];
        } else {
          auxGeneralStatus.forEach(status => {
            if (status === this.REJECTED) {
              generalStatus = this.REJECTED;
            } else if (status === this.APPROVED) {
              generalStatus = this.APPROVED;
            } else if (status === this.PENDING) {
              generalStatus = this.PENDING;
            } else if (status === this.PENDING_VERIFY) {
              generalStatus = this.PENDING_VERIFY;
            }
          });

          generalStatus =
            auxGeneralStatus.length === auxAccepted
              ? this.ACCEPTED
              : generalStatus;
        }

        newData.push({
          applicationId: item.campusReviewId,
          review: item.review,
          calification: item.calification,
          estudiante: item.name,
          campus: this.getCampus(item.campus),
          state_icon: icon_status,
          state: this.getStatus(generalStatus),
          pendingPayment: item.pendingPayment,
          nationality: Student,
          created_at: Vue.moment(String(item.created_at)).format(date_format),
          start_date: "",
          data: ListReviews,
        });
      });

      return newData;
    },
  },
  computed: {
    ...mapGetters(["campus"]),
    url() {
      return `${process.env.VUE_APP_CDN_PATH}images/flags`;
    },
  },
};
</script>
<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #dfdfdf91 !important;
}

.color-green > span > svg {
  color: #4caf50 !important;
}
.color-orange > span > svg {
  color: #ff9800 !important;
}
.color-red > span > svg {
  color: #f44336 !important;
}
.color-deep-orange > span > svg {
  color: #ff5722 !important;
}

.cursor-auto {
  cursor: auto !important;
}
</style>
