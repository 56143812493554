<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row gutters="2">
          <v-col cols="12" md="12">
            <!-- HEADER TABS -->
            <v-tabs v-model="currentTab" fixed-tabs class="new-style-tabs">
              <v-tab v-for="(tab, index) in tabs" :key="index">
                {{ tab }}
              </v-tab>
            </v-tabs>

            <!-- CONTENT TABS -->
            <v-tabs-items v-model="currentTab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <Table :status="REJECTED" :filterBy="filterBy" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <Table :status="APPROVED" :filterBy="filterBy" />
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <Table :status="PENDING" :filterBy="filterBy" />
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <Table :status="PENDING_VERIFY" :filterBy="filterBy" />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "./components/Table.vue";

export default {
  components: {
    Table,
  },
  data() {
    return {
      currentTab: 0,
      tabs: [
        this.$t("REVIEWS.TABLE.TAB0"),
        this.$t("REVIEWS.TABLE.TAB1"),
        this.$t("REVIEWS.TABLE.TAB2"),
        this.$t("REVIEWS.TABLE.TAB3"),
      ],
      itemsPerPage: 10,
      headers: [
        {
          text: this.$t("CAMPUS.NAME"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("CAMPUS.CITY"),
          value: "google_place.translation",
          sortable: false,
        },
      ],
      REJECTED: 0,
      APPROVED: 1,
      PENDING: 2,
      PENDING_VERIFY: 3,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("REVIEWS.TABLE.TITLE") },
    ]);
  },
  methods: {
    newCampus() {
      this.$router.push({ name: "campus-new" });
    },
  },
};
</script>

<style scoped>
.v-tab::after {
  content: "";
  width: 1px;
  height: 25px;
  background-color: #b3b3b3;
  position: absolute;
  right: 0;
}

.v-tab:last-child::after {
  width: 0;
  height: 0;
}

.new-style-tabs {
  border-right: 1px solid #e5e5ea !important;
  border-left: 1px solid #e5e5ea !important;
  border-top: 1px solid #e5e5ea !important;
}
</style>
